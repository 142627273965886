import "./App.css";
import React, { Suspense, lazy, useState } from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./store";

import { Provider } from "react-redux";
import setAuthToken from "./utils/setAuthToken";
import AdminRoute from "./routes/AdminRoute";
import Sidebar from "./layout/Sidebar";
import Navbar from "./layout/Navbar";
import { saveConfig, setAdmin } from "./actions/AuthAction";
import { setConfig } from "./reducers/configReducer";

import { isPermitted } from "./utils/PermissionManager";
import { setProfile } from "./reducers/authReducer";
import Bottombar from "./layout/BottomBar";
import { siteInfo } from "./utils/SiteInfo";

const OrderInvoice = lazy(() => import("./pages/OrderInvoice"));
const RateSettings = lazy(() => import("./pages/RateSettings"));
const ChargeSetting = lazy(() => import("./pages/ChargeSettings"));
const BecommerceSetting = lazy(() => import("./pages/BecommerceSetting"));

const Invoice = lazy(() => import("./pages/Invoice"));

const OfficeDelivery = lazy(() => import("./pages/OfficeDelivery"));

const Coupon = lazy(() => import("./pages/Coupons"));
const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const DeliveryComplete = lazy(() => import("./pages/DeliveryComplete"));
const Delivery = lazy(() => import("./pages/Delivery"));

const Tracking = lazy(() => import("./pages/Tracking"));
const Sorting = lazy(() => import("./pages/Sorting"));

const Customers = lazy(() => import("./pages/Customers"));
const Login = lazy(() => import("./pages/Login"));
const Orders = lazy(() => import("./pages/Orders"));
const ManageOrder = lazy(() => import("./pages/ManageOrder"));
const Admins = lazy(() => import("./pages/Admins"));
const Payments = lazy(() => import("./pages/Payments"));
const Profile = lazy(() => import("./pages/Profile"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));
const Purchasing = lazy(() => import("./pages/Purchasing"));

if (localStorage.config) {
  let config = JSON.parse(localStorage.config);
  let profile = config.profile;
  store.dispatch(setProfile(profile));
  store.dispatch(setConfig(config));
}

if (localStorage[siteInfo.sitekey]) {
  setAuthToken(localStorage[siteInfo.sitekey]);
  store.dispatch(setAdmin(true));
  store.dispatch(saveConfig());
}

const Dashboard = lazy(() => import("./pages/Dashboard"));

function App() {
  const client = window.screen.width > 768 ? "web" : "app";
  const [show, setshow] = useState(client === "web" ? false : true);
  const [trigger, settrigger] = useState(false);

  const id = store.getState().auth?.profile?.phone;

  const roles = store.getState().auth?.profile?.roles
    ? store.getState().auth?.profile?.roles
    : [];

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#FEC538",
          },
          components: {
            Button: {
              colorPrimary: "#FFD670",
              colorTextLightSolid: "#000",
            },
            Menu: {
              itemSelectedBg: "#F6F1E5",
              itemSelectedColor: "#000",
            },
          },
        }}
      >
        <BrowserRouter>
          <Suspense fallback={<div className="pageProgress"></div>}>
            <div className="App">
              <Sidebar state={{ show, setshow }} />
              <div>
                <Navbar state={{ settrigger, setshow, show }} />
                {roles?.length > 0 && <Bottombar />}
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Dashboard />}
                      />
                    }
                  />

                  {isPermitted(roles, ["admin", "customer_care"]) && (
                    <Route
                      exact
                      path="/customers"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Customers />}
                        />
                      }
                    />
                  )}
                  <Route
                    exact
                    path="/orders"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Orders />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/order/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<ManageOrder />}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/order/invoice/:id"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<OrderInvoice />}
                      />
                    }
                  />
                  {/* {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipment/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ShipmentDetails />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/courier"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CourierHub />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />
                  )} */}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Invoice />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/settings/rates"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<RateSettings />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/settings/shipping-charges"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ChargeSetting />}
                        />
                      }
                    />
                  )}
                  {["01601040108", "01614390717"].includes(id) &&
                    isPermitted(roles, ["admin"]) && (
                      <Route
                        exact
                        path="/settings/becommerce"
                        element={
                          <AdminRoute
                            state={{ show, trigger }}
                            component={<BecommerceSetting />}
                          />
                        }
                      />
                    )}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Delivery />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryComplete />}
                        />
                      }
                    />
                  )}

                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/invoice"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<OfficeDelivery />}
                        />
                      }
                    />
                  )}

                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/payable-calculator/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CashCounter />}
                        />
                      }
                    />
                  )} */}

                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/cash-counter/:id?"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<CashCounter />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/office-delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<OfficeDelivery />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Shipments />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/multiple-shipments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MultipleShipments />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Delivery />}
                        />
                      }
                    />
                  )} */}
                  {/* {isPermitted(roles, ["admin", "delivery"]) && (
                    <Route
                      exact
                      path="/delivery/:id"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<DeliveryComplete />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/monthly-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<MonthlyReport />}
                        />
                      }
                    />
                  )}
                  {/* {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/profit-report"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<ProfitReport />}
                        />
                      }
                    />
                  )} */}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/coupons"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Coupon />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "shipment"]) && (
                    <Route
                      exact
                      path="/sorting"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Sorting />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin"]) && (
                    <Route
                      exact
                      path="/admins"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Admins />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "purchase"]) && (
                    <Route
                      exact
                      path="/purchasing"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Purchasing />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "purchase"]) && (
                    <Route
                      exact
                      path="/tracking"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Tracking />}
                        />
                      }
                    />
                  )}
                  {isPermitted(roles, ["admin", "accountant"]) && (
                    <Route
                      exact
                      path="/payments"
                      element={
                        <AdminRoute
                          state={{ show, trigger }}
                          component={<Payments />}
                        />
                      }
                    />
                  )}

                  <Route
                    exact
                    path="/profile"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={<Profile />}
                      />
                    }
                  />

                  <Route exact path="/login" element={<Login />} />
                  <Route
                    path="*"
                    element={
                      <AdminRoute
                        state={{ show, trigger }}
                        component={
                          <div
                            style={{
                              height: "calc(100vh - 78px)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                            }}
                          >
                            <ComingSoon />
                          </div>
                        }
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </Suspense>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
