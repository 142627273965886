import { Avatar } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { siteInfo } from "../utils/SiteInfo";
import {
  CloudServerOutlined,
  GoldOutlined,
  HomeOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { IoCashOutline } from "react-icons/io5";
export default function Navbar(props) {
  const profile = useSelector((state) => state.auth?.profile);
  const location = useLocation();

  let routes = [
    {
      path: "/",
      title: "Dashboard",
      icon: HomeOutlined,
    },
    {
      path: "/purchasing",
      title: "Purchasing",
      icon: CloudServerOutlined,
    },
    {
      path: "/orders",
      title: "Orders",
      icon: GoldOutlined,
    },
    {
      path: "/customers",
      title: "Customers",
      icon: TeamOutlined,
    },
    {
      path: "/payments",
      title: "Payments",
      icon: IoCashOutline,
    },
    {
      path: "/admins",
      title: "Admins",
      icon: UsergroupAddOutlined,
    },
  ];

  const getRouteName = (pathname) => {
    let found = routes.find((el) => el.path === pathname);
    if (found) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <found.icon size={18} color="#000" />
          <span
            style={{
              color: "#000",
              marginLeft: 8,
              textTransform: "capitalize",
            }}
          >
            {found.title}
          </span>
        </div>
      );
    } else {
      let arr = pathname.split("/");
      let name = arr[arr.length - 1];

      console.log(name);

      name =
        name && typeof name === "string" ? name?.split("-").join(" ") : name;

      if (pathname.includes("/create-post")) {
        name = "Create Post";
      }
      if (pathname.includes("/edit-post")) {
        name = "Edit Post";
      }
      if (pathname.includes("/delivery/")) {
        name = "Invoice";
      }
      if (pathname.includes("/cash-counter/")) {
        name = "Cash Counter";
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ color: "#000", textTransform: "capitalize" }}>
            {name}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      {location.pathname !== "/login" && (
        <nav
          style={{
            backgroundColor: siteInfo.navColor,
            //  backgroundImage: `url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23c0c0c0' fill-opacity='0.17'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
            padding: "0px 1.5rem",
            position: "fixed",
            boxShadow: "0 1px 16px rgba(0,0,0,.1)",
            zIndex: 1,
            height: "60px",
            color: "white",
            top: 0,
            left: 240,
            right: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
              color: "#000",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getRouteName(location.pathname)}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link href="/profile" className="logout">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Avatar style={{ backgroundColor: "#fff", color: "#f56a00" }}>
                    U
                  </Avatar>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginLeft: "0.5rem",
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "18px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      {profile?.name ? profile?.name : "Administration"}
                    </span>
                    <span
                      style={{
                        lineHeight: "18px",
                        fontWeight: 500,
                        color: "#000",
                        fontSize: 12,
                        textTransform: "capitalize",
                      }}
                    >
                      {profile?.phone ? profile?.phone : "role"}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
