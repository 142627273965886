import { setAuth, setProfile } from "../reducers/authReducer";
import { setConfig } from "../reducers/configReducer";
import axios from "axios";
import getError from "../utils/getError";
import setAuthToken from "../utils/setAuthToken";
import url from "../utils/url";
import { siteInfo } from "../utils/SiteInfo";

export const loginAdmin = (data, loginAfterFunction) => (dispatch) => {
  axios
    .post(url + "/auth", data)
    .then((res) => {
      if (res.data && res.data.success) {
        console.log(res.data);
        const { access_token } = res.data;
        setAuthToken(access_token);
        localStorage.setItem(siteInfo.sitekey, access_token);
        dispatch(setAuth(true));
        dispatch(saveConfig());
        loginAfterFunction(true);
      }
    })
    .catch((err) => {
      loginAfterFunction(false, getError(err));
    });
};

export const saveConfig = () => (dispatch) => {
  axios
    .get(url + "/config")
    .then((res) => {
      if (res.data.result) {
        localStorage.setItem("config", JSON.stringify(res.data.result));
        dispatch(setConfig(res.data.result));
        dispatch(setProfile(res.data.result?.profile));
      }
    })
    .catch((err) => {});
};
export const saveProfile = () => (dispatch) => {
  axios
    .get(url + "/profile")
    .then((res) => {
      if (res.data?.result) {
        let role = res.data?.result?.role ? res.data?.result?.role : "";
        dispatch(setProfile(res.data?.result));
      }
    })
    .catch((err) => {});
};

export const logoutAdmin = (afterFunction) => (dispatch) => {
  localStorage.removeItem(siteInfo.sitekey);
  localStorage.removeItem("config");
  setAuthToken(null);
  dispatch(setAuth(false));
  dispatch(setProfile(null));
  dispatch(setConfig(null));
  afterFunction(true);
};

export const setAdmin = (isAuthenticated) => (dispatch) => {
  dispatch(setAuth(isAuthenticated));
};
export const setAdminProfile = (data) => {
  return {
    type: "SET_ADMIN_PROFILE",
    payload: data,
  };
};

export const setAdminRole = (data) => {
  return {
    type: "SET_ADMIN_ROLES",
    payload: data,
  };
};
